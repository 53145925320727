import React from 'react'
/* import { Link } from 'gatsby' */
/* import { graphql } from 'gatsby' */

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import styles from "./mission.module.css"

const MissionPage = () => (
	<Layout>
	<SEO title="理念" />
		<div className={styles.container}>
			<h1>理念</h1>
			<p>
			現代は、パソコンやスマートフォン、タブレットなどのIT機器が身近になり、いつでもどこでも誰でも当たり前のようにインターネットという情報空間にアクセスできます。
			地域や国境といった地理的な制約を超えて、最新のテクノロジーを活用した素晴らしい商品・サービスを利用したり、有益な情報を得ることができるようになっているのです。
			</p>

			<p>
			しかしながら、すべての人達がその恩恵を受けることができていると言えるでしょうか。
			テクノロジーの進歩は目覚ましいですが、その裏返しとして、ギャップが生まれ、課題も残されているのが現実です。
			</p>

			<p>
			ITの世界に身を置いている立場の自分に何か出来ることはないかと考えた時、ひとつの答えが浮かびました。
			</p>

			<strong>
			「テクノロジーによりもたらされる恩恵を、出来るだけ多くの人達に届ける橋渡し役になることはできないか」
			</strong>

			<p>
			私のミッションは、テクノロジーによりもたらされる恩恵をより多くの人達に届け、それによって、多くの人達の生活を豊かにすることだと考えています。
			</p>

			<span className={styles.quote}>
			&mdash;Empower your life through technology.
			</span>
		</div>
	</Layout>
)

export default MissionPage

/* [EOF] */
